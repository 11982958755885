.container {
    position: relative;
    height: 100vh;
    width: 100%;
  }
  
  .top-controls {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .logout-button {
    cursor: pointer;
    background-color: #000;
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  
  .search-box {
    width: 320px;
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
    border-radius: 6px;
    outline: none;
    font-size: 16px;
  }
  
  .options-section {
    position: absolute;
    top: 70px;
    left: 20px;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 12px;
    border-radius: 8px;
  }
  
  .locate-me-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: #000;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
  }
  
  @media (max-width: 600px) {
    .container {
      height: 100dvh;
      overflow: hidden;
    }
  
    .top-controls {
      top: 15px;
      width: 95%;
      padding: 0 10px;
      box-sizing: border-box;
    }
  
    .search-box {
      width: 100%;
      font-size: 14px;
      padding: 8px;
    }
  
    .logout-button {
      flex-shrink: 0; /* prevents shrinking below content width */
      padding: 6px 8px;
      font-size: 13px;
    }
  
    .options-section {
      top: 70px;
      max-width: 90%;
      font-size: 12px;
    }
  
    .locate-me-btn {
      bottom: 80px;
    }
  }
  